@import '~handsontable/dist/handsontable.full.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  color: #363636;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#nprogress .bar {
  z-index: 4031!important;
}

.handsontable tbody th {
  text-align: left;
  white-space: pre-wrap;
}